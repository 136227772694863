import debounce from 'lodash.debounce';
import { mapState } from 'vuex';
import { FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        searchCompanies: null,
        isCompaniesLoading: false,
        companiesPaginationPage: 1,
        debouncedGetCompanies: null
    }),
    computed: {
        ...mapState('companies', [
            'rawCompanies',
            'totalCompaniesAmount'
        ])
    },
    watch: {
        searchCompanies: {
            handler (val) {
                if (!val || this.isCompaniesLoading) {
                    return;
                }

                this.debouncedGetCompanies(val);
            }
        }
    },
    methods: {
        getCompanies (additionalParams = null) {
            this.isCompaniesLoading = true;

            let params = {};

            if (typeof additionalParams === 'string') {
                params['filter[name][match]'] = additionalParams;
            } else if (typeof additionalParams === 'object') {
                params = { ...additionalParams };
            }

            return this.$store.dispatch('companies/getCompanies', params)
                .then(() => {
                    this.companiesPaginationPage = 1;
                })
                .finally(() => {
                    this.isCompaniesLoading = false;
                });
        },
        getCompaniesLazyLoading (additionalParams = null) {
            if (this.totalCompaniesAmount > this.rawCompanies.length) {
                this.isCompaniesLoading = true;

                this.companiesPaginationPage += 1;

                let params = {};

                if (typeof additionalParams === 'object') {
                    params = { ...additionalParams };
                }

                params['page[number]'] = this.companiesPaginationPage;

                const _name = this.searchCompanies || this.filtersSearching?.[FILTERS_SEARCHING.COMPANIES];

                if (_name) {
                    params['filter[name][match]'] = _name;
                }

                return this.$store.dispatch('companies/getCompaniesLazyLoading', params)
                    .finally(() => {
                        this.isCompaniesLoading = false;
                    });
            }
        }
    },
    created () {
        this.debouncedGetCompanies = debounce(this.getCompanies, 1000);
    }
};
