<template>
    <div class="pt-4">
        <ValidationObserver ref="obs">
            <TextField :label="$t('labels.login')"
                       name="login"
                       :disabled="disabled"
                       :rules="{ required: true, is_not: 'helpdesk' }"
                       autocomplete="off"
                       v-model="login"></TextField>
            <TextField v-model="password"
                       slotName="append"
                       name="password"
                       :disabled="disabled"
                       :type="passwordFieldType"
                       :rules="{ required: true, min: 6, capitalLetter: true }"
                       autocomplete="off"
                       :label="$t('labels.password')">
                <template slot="slotContent">
                    <v-icon @click="togglePassword"
                            data-test="togglePasswordBtn"
                            size="20"
                            color="medium_grey">{{ passwordFieldIcon }}
                    </v-icon>
                </template>
            </TextField>
            <TextField v-model="confirmPassword"
                       slotName="append"
                       name="confirmPassword"
                       :disabled="disabled"
                       :type="confirmPasswordFieldType"
                       :rules="{ required: true, password:`${password}` }"
                       :label="$t('labels.confirm_password')">
                <template slot="slotContent">
                    <v-icon @click="toggleConfirmPassword"
                            size="20"
                            color="medium_grey">{{ confirmPasswordFieldIcon }}
                    </v-icon>
                </template>
            </TextField>
        </ValidationObserver>
        <ConfirmationDialog :dialog="showConfirmationDialog"
                            data-test="ConfirmationDialog"
                            @confirm="handleConfirmation($event)"
                            :text="$t('messages.save_changes')"
                            :action-button-title="$t('buttons.save')"></ConfirmationDialog>
        <ValidationError v-if="showError"></ValidationError>
    </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapFields } from 'vuex-map-fields';
import { ICONS } from '@/constants/icons';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';
import TextField from '@/components/widgets/forms/TextField';
import ValidationError from '@/components/widgets/ValidationError';

export default {
    name: 'ChangeLoginData',
    components: { ValidationObserver, ConfirmationDialog, TextField, ValidationError },
    props: {
        disabled: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        showConfirmationDialog: false,
        loader: false,
        showError: false,
        passwordFieldType: 'password',
        passwordFieldIcon: ICONS.DETAILS,
        confirmPasswordFieldType: 'password',
        confirmPasswordFieldIcon: ICONS.DETAILS
    }),
    computed: {
        ...mapFields('manageEmployee', [
            'employeeData.login',
            'employeeData.password',
            'employeeData.confirmPassword'
        ])
    },
    methods: {
        close () {
            this.showError = false;
            this.$emit('close');
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        togglePassword () {
            if (this.passwordFieldType === 'password') {
                this.passwordFieldType = 'text';
                this.passwordFieldIcon = ICONS.DETAILS_OFF;
            } else {
                this.passwordFieldType = 'password';
                this.passwordFieldIcon = ICONS.DETAILS;
            }
        },
        toggleConfirmPassword () {
            if (this.confirmPasswordFieldType === 'password') {
                this.confirmPasswordFieldType = 'text';
                this.confirmPasswordFieldIcon = ICONS.DETAILS_OFF;
            } else {
                this.confirmPasswordFieldType = 'password';
                this.confirmPasswordFieldIcon = ICONS.DETAILS;
            }
        },
        validate () {
            return this.$refs.obs.validate();
        },
        reset () {
            return this.$refs.obs.reset();
        },
        handleConfirmation (event) {
            if (event) {
                this.save();
                this.showConfirmationDialog = false;
            } else {
                this.showConfirmationDialog = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
