<template>
    <div class="location">
        <span>{{ item.text }}</span>
        <Checkbox :value="chosen"
                  @input="handleAssigning($event)"
                  dense
                  class-name="mt-0 ml-2 pt-0"></Checkbox>
    </div>
</template>

<script>
import Checkbox from '@/components/widgets/forms/Checkbox';
import { mapState } from 'vuex';

export default {
    name: 'TreeViewLocation',
    components: { Checkbox },
    props: {
        type: {
            type: String,
            required: true,
            validate: (v) => {
                const types = ['templates', 'employees', 'kinds'];
                return types.includes(v);
            }
        },
        itemId: {
            type: [String, Number],
            required: true
        },
        item: {
            type: Object,
            required: true
        }

    },
    data: () => ({
        chosen: false
    }),
    computed: {
        ...mapState('assignCustomer', [
            'assignedData'
        ]),
        isTemplateAssigned () {
            return this.assignedData !== null
                ? this.assignedData.point_ids.includes(parseInt(this.item.value))
                : false;
        }
    },
    watch: {
        isTemplateAssigned: {
            handler (val) {
                this.chosen = val;
            },
            immediate: true
        }
    },
    methods: {
        emitUpdateAssign () {
            this.$emit('update-assign');
        },
        assignTemplate () {
            return this.$store.dispatch('templates/assignTemplatesToPoints', {
                templates_ids: [parseInt(this.itemId)],
                points_ids: [parseInt(this.item.value)]
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        unassignTemplate () {
            return this.$store.dispatch('templates/unassignTemplatesFromPoints', {
                templates_ids: [parseInt(this.itemId)],
                points_ids: [parseInt(this.item.value)]
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        assignEmployees () {
            return this.$store.dispatch('employees/manageAssignmentToLocations', {
                method: 'assign',
                records: {
                    employeesIds: [parseInt(this.itemId)],
                    locationsIds: [parseInt(this.item.value)]
                }
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        unassignEmployees () {
            return this.$store.dispatch('employees/manageAssignmentToLocations', {
                method: 'unassign',
                records: {
                    employeesIds: [parseInt(this.itemId)],
                    locationsIds: [parseInt(this.item.value)]
                }
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        assignKinds () {
            return this.$store.dispatch('incidents/assignKindsFromPoints', {
                templates_ids: [parseInt(this.itemId)],
                points_ids: [parseInt(this.item.value)]
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        unassignKinds () {
            return this.$store.dispatch('incidents/unassignKindsFromPoints', {
                templates_ids: [parseInt(this.itemId)],
                points_ids: [parseInt(this.item.value)]
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        handleAssigning (val) {
            if (val !== this.chosen) {
                this.chosen = val;
                switch (this.type) {
                case 'templates':
                    val ? this.assignTemplate() : this.unassignTemplate();
                    break;
                case 'employees':
                    val ? this.assignEmployees() : this.unassignEmployees();
                    break;
                case 'kinds':
                    val ? this.assignKinds() : this.unassignKinds();
                    break;
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
    .location {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        &:hover {
            background-color: #eee;
        }
    }
</style>
