import { render, staticRenderFns } from "./EmployeeDetails.vue?vue&type=template&id=698c85a2&scoped=true&"
import script from "./EmployeeDetails.vue?vue&type=script&lang=js&"
export * from "./EmployeeDetails.vue?vue&type=script&lang=js&"
import style0 from "./EmployeeDetails.vue?vue&type=style&index=0&id=698c85a2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "698c85a2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VCol,VFlex,VImg,VLayout,VRow,VSheet})
