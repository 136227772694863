import debounce from 'lodash.debounce';
import { mapGetters, mapState } from 'vuex';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        searchTemplates: null,
        secondSearchTemplates: null,
        isTemplatesLoading: false,
        isSecondTemplatesLoading: false,
        debounceGetTemplates: null,
        debounceGetSecondTemplates: null,
        templatesPaginationPage: 1,
        secondTemplatesPaginationPage: 1
    }),
    computed: {
        ...mapState('templates', [
            'totalTemplatesAmount',
            'rawTemplates',
            'secondRawTemplates'
        ]),
        ...mapGetters('templates', [
            'filterableTemplates'
        ])
    },
    watch: {
        searchTemplates: {
            handler (val, oldVal) {
                if (!val || this.isTemplatesLoading || val === oldVal) {
                    return;
                }

                this.debounceGetTemplates(val);
            }
        }
    },
    methods: {
        getParamans (title, additionalParams) {
            this.isTemplatesLoading = true;
            const params = {
                'filter[archive]': false,
                'filter[current]': true
            };

            if (title) {
                params['filter[name][match]'] = title;
            }

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }
            return params;
        },
        getTemplates (title, additionalParams = null) {
            const params = this.getParamans(title, additionalParams);
            return this.$store.dispatch('templates/getTemplates', params)
                .then(() => {
                    this.templatesPaginationPage = 1;
                })
                .finally(() => {
                    this.isTemplatesLoading = false;
                });
        },
        getSecondTemplates (title, additionalParams = null) {
            const params = this.getParamans(title, additionalParams);
            params.secondTemplate = true;
            return this.$store.dispatch('templates/getTemplates', params)
                .then(() => {
                    this.secondTemplatesPaginationPage = 1;
                })
                .finally(() => {
                    this.isSecondTemplatesLoading = false;
                });
        },
        getTemplatesLazyLoading (additionalParams = null) {
            if (this.totalTemplatesAmount > this.filterableTemplates.length) {
                this.isTemplatesLoading = true;

                this.templatesPaginationPage += 1;

                const params = {
                    'filter[archive]': false,
                    'filter[current]': true,
                    'page[number]': this.templatesPaginationPage
                };

                const _name = this.searchTemplates || this.filtersSearching?.[FILTERS_SEARCHING.TEMPLATE];

                if (_name) {
                    params['filter[name][match]'] = _name;
                }

                if (additionalParams) {
                    Object.keys(additionalParams).forEach(k => {
                        params[k] = additionalParams[k];
                    });
                }
                return this.$store.dispatch('templates/getTemplatesLazyLoading', params)
                    .finally(() => {
                        this.isTemplatesLoading = false;
                    });
            }
        },
        getSecondTemplatesLazyLoading (additionalParams = null) {
            if (this.totalTemplatesAmount > this.filterableTemplates.length) {
                this.isSecondTemplatesLoading = true;

                this.secondTemplatesPaginationPage += 1;

                const params = {
                    'filter[archive]': false,
                    'filter[current]': true,
                    'page[number]': this.secondTemplatesPaginationPage,
                    'secondTemplate': true
                };

                const _name = this.secondSearchTemplates || this.filtersSearching?.[FILTERS_SEARCHING.TEMPLATE];

                if (_name) {
                    params['filter[name][match]'] = _name;
                }

                if (additionalParams) {
                    Object.keys(additionalParams).forEach(k => {
                        params[k] = additionalParams[k];
                    });
                }

                return this.$store.dispatch('templates/getTemplatesLazyLoading', params)
                    .finally(() => {
                        this.isSecondTemplatesLoading = false;
                    });
            }
        },
        referralStartTemplateCallback (newVal) {
            const filterPropertyName = this.isModal ? 'modalFiltersConfig' : 'filtersConfig';
            const mutationTitle = this.isModal ? 'filters/SET_MODAL_CONFIG' : 'filters/SET_CONFIG';

            if (newVal && newVal.length > 0) {
                this[filterPropertyName].find(el => el.model === FILTERS.END_TEMPLATE_ID).disabled = false;
                this[filterPropertyName].find(el => el.model === FILTERS.END_TEMPLATE_ID).successMessages = '';
                this.debouncedGetSites(null, null, newVal);
            } else {
                this[filterPropertyName].find(el => el.model === FILTERS.END_TEMPLATE_ID).disabled = true;
                this[filterPropertyName].find(el => el.model === FILTERS.END_TEMPLATE_ID).successMessages = this.$t('messages.choose_customer');

                this.$refs.filtersBar.resetSpecifyFilter(FILTERS.END_TEMPLATE_ID);
            }
            this.$store.commit(mutationTitle, this[filterPropertyName]);
        }
    },
    created () {
        this.debounceGetTemplates = debounce(this.getTemplates, 1000);
        this.debounceGetSecondTemplates = debounce(this.getSecondTemplates, 1000);
    }
};
