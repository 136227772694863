import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';
import { FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        debouncedGetEmployees: null,
        isEmployeesLoading: false,
        searchEmployees: null,
        employeesPaginationPage: 1
    }),
    computed: {
        ...mapState('employees', [
            'totalEmployeesAmount'
        ]),
        ...mapGetters('employees', [
            'employees'
        ])
    },
    watch: {
        searchEmployees: {
            handler (val) {
                if (!val || this.isEmployeesLoading) {
                    return;
                }

                this.debouncedGetEmployees(val);
            }
        }
    },
    methods: {
        getEmployees (additionalParams = null) {
            this.isEmployeesLoading = true;

            let params = {};
            // fix for items appearing in employee autocomplete, this may break items if new usages added
            // employee and item logic should be split, it generates a lot of confusion.
            // Problem occurs because this distinct abstractions are merged together in database table which is a huge mistake.
            params['filter[is_item]'] = false;

            if (typeof additionalParams === 'string') {
                params['filter[full_name][match]'] = additionalParams;
            } else if (typeof additionalParams === 'object') {
                params = { ...additionalParams };
            }
            return this.$store.dispatch('employees/getEmployees', params)
                .then(() => {
                    this.employeesPaginationPage = 1;
                })
                .finally(() => {
                    this.isEmployeesLoading = false;
                });
        },
        getEmployeesLazyLoading (additionalParams = null) {
            if (this.totalEmployeesAmount > this.employees.length) {
                this.isEmployeesLoading = true;

                this.employeesPaginationPage += 1;

                let params = {};

                if (typeof additionalParams === 'object' && additionalParams !== null) {
                    params = { ...additionalParams };
                }

                params['page[number]'] = this.employeesPaginationPage;

                const _name = this.searchEmployees || this.filtersSearching?.[FILTERS_SEARCHING.EMPLOYEES];

                if (_name) {
                    params['filter[full_name][match]'] = _name;
                }

                return this.$store.dispatch('employees/getEmployeesLazyLoading', params)
                    .finally(() => {
                        this.isEmployeesLoading = false;
                    });
            }
        }
    },
    created () {
        this.debouncedGetEmployees = debounce(this.getEmployees, 1000);
    }
};
