<template>
    <v-sheet color="white"
             class="pt-4 pb-0"
             elevation="1">
        <div class="px-4" v-if="positionType !== 'elements' && canEditEmployee">
            <p class="divider">{{ $t('labels.login') }}</p>
            <ChangeLoginData ref="loginForm"
                             :disabled="(editEmployeeId && !changeLoginVisibility)"
                             @fetch="refreshEmployeeData"
                             @visibility="changeLoginVisibility = $event"
                             @close="closeChangeLogin"></ChangeLoginData>
            <v-btn v-if="isChangeLoginVisibility()"
                   color="secondary"
                   small
                   @click="showChangeLogin"
                   class="ml-3 mb-4">{{ $t('buttons.change_login_data') }}
                >
            </v-btn>
        </div>
        <ValidationObserver ref="obs">
            <v-layout align-stretch
                      :wrap="isMobile">
                <v-flex class="px-4"
                        sm6>
                    <p class="divider">{{ formTitle }}</p>
                    <TextField v-model.trim="firstName"
                               name="firstName"
                               ref="firstName"
                               :label="isElement && $t('labels.name_1') || $t('labels.first_name')"
                               rules="required"></TextField>
                    <TextField v-model.trim="lastName"
                               name="lastName"
                               ref="lastName"
                               :label="isElement && $t('labels.name_2') || $t('labels.last_name')"
                    ></TextField>
                    <TextField v-model="nameId1"
                               name="nameId1"
                               ref="nameId1"
                               :rules="{ max: 40 }"
                               :label="isElement && $t('labels.description_1') || $t('labels.employee_ID_1')"></TextField>
                    <TextField v-model="nameId2"
                               name="nameId2"
                               :rules="{ max: 40 }"
                               ref="nameId2"
                               :label="isElement && $t('labels.description_2') || $t('labels.employee_ID_2')"></TextField>
                    <template v-if="!isElement">
                        <p class="divider">{{ $t('employees.contact_data') }}</p>
                        <TextField v-model="email"
                                   name="email"
                                   ref="email"
                                   :rules="{ max: 60, email: true }"
                                   :label="$t('labels.email')"></TextField>
                        <TextField v-model="phone"
                                   name="phone"
                                   ref="phone"
                                   rules="phone"
                                   :label="$t('labels.phone')"></TextField>
                    </template>
                    <template v-if="!isElement">
                        <p class="divider">{{ $t('employees.device_data') }}</p>
                        <Autocomplete :label="$t('labels.deviceType')"
                                  :items="deviceTypes"
                                  name="deviceType"
                                  ref="deviceType"
                                  :rules="`${!!deviceSerialNumber ? 'required' : ''}`"
                                  clearable
                                  v-model="deviceType"></Autocomplete>
                        <TextField v-model="deviceSerialNumber"
                                   name="deviceSerialNumber"
                                   ref="deviceSerialNumber"
                                   :rules="`${!!deviceType ? 'required' : ''}`"
                                   :label="$t('labels.deviceSerialNumber')"></TextField>
                    </template>
                    <template v-if="positionType === 'employees' && position === typeItemId">
                        <p class="text--xs error--text">{{ $t('elements.unavailable_fields') }}</p>
                    </template>

                </v-flex>
                <v-flex class="px-4"
                        sm6>
                    <p class="divider" v-if="!isElement">{{ $t('employees.business_data') }}</p>
                    <p v-else class="divider"><span class="transparent--text">.</span></p>
                    <Autocomplete :label="$t('navigation.position')"
                                  :items="positions"
                                  v-if="positionType === 'employees'"
                                  name="position"
                                  ref="position"
                                  rules="required"
                                  :loading="isPositionsLoading"
                                  :search-input.sync="searchPositions"
                                  @load-more-items="getPositionsLazyLoading()"
                                  @focus="getPositions()"
                                  v-model="position"></Autocomplete>
                    <Autocomplete v-if="!isElement"
                                  :label="$t('labels.company')"
                                  :items="filterableCompanies"
                                  name="company"
                                  ref="company"
                                  clearable
                                  :loading="isCompaniesLoading"
                                  :search-input.sync="searchCompanies"
                                  @focus="getCompanies"
                                  @load-more-items="getCompaniesLazyLoading"
                                  v-model="company"></Autocomplete>
                    <Autocomplete :label="$t('labels.customer')"
                                  name="customersIds"
                                  clearable
                                  @click:clear="getCustomers(null, null, true)"
                                  @focus="getCustomers(null, null, true)"
                                  @load-more-items="getCustomersLazyLoading(true)"
                                  :items="listingCustomers"
                                  :loading="isCustomersLoading"
                                  :search-input.sync="searchCustomers"
                                  v-model="customerId"></Autocomplete>
                    <Autocomplete :label="$t('labels.site')"
                                  name="site_id"
                                  :items="sites"
                                  clearable
                                  @click:clear="getSites(null, null, customerId)"
                                  @focus="getSites(null, null, customerId)"
                                  @load-more-items="getSitesLazyLoading(customerId)"
                                  :loading="isSitesLoading"
                                  :search-input.sync="searchSitesPerCustomer"
                                  v-model="siteId"></Autocomplete>
                    <Autocomplete :label="$t('labels.location')"
                                  :items="locations"
                                  name="location"
                                  ref="location"
                                  clearable
                                  :loading="isLocationsLoading"
                                  :search-input.sync="searchLocations"
                                  @click:clear="getLocations(null, null, siteId)"
                                  @focus="getLocations(null, null, siteId)"
                                  @load-more-items="getLocationsLazyLoading(siteId)"
                                  @change="locationChanged"
                                  :rules="`${(!!deviceType || !!deviceSerialNumber) && !isElement ? 'required' : ''}`"
                                  v-model="location"></Autocomplete>
                    <Autocomplete v-if="isElement"
                                  :label="$t('labels.template')"
                                  :items="filterableTemplates"
                                  :disabled="!location"
                                  name="template"
                                  ref="template"
                                  clearable
                                  :loading="isTemplatesLoading"
                                  :search-input.sync="searchTemplates"
                                  @click:clear="getTemplates(null, template)"
                                  @focus="getTemplates(null, templatesFilteringParams)"
                                  @load-more-items="getTemplatesLazyLoading(null, templatesFilteringParams)"
                                  v-model="template"></Autocomplete>
                    <Autocomplete :label="$t('labels.group')"
                                  :items="filterableEmployeesGroups"
                                  name="employeesGroups"
                                  ref="employeesGroups"
                                  :multiple="true"
                                  clearable
                                  :loading="isEmployeesGroupsLoading"
                                  :search-input.sync="searchEmployeesGroups"
                                  @focus="getEmployeesGroups"
                                  @load-more-items="getEmployeesGroupsLazyLoading"
                                  v-model="employeesGroups"></Autocomplete>
                    <TextField v-model="nfcCode"
                               name="nfcCode"
                               :rules="{ max: 40 }"
                               ref="nfcCode"
                               :label="$t('labels.nfc_tag')"></TextField>
                    <TextField v-model="idCard"
                               v-if="!isElement"
                               name="idCard"
                               ref="idCard"
                               :rules="{ max: 40 }"
                               :success-messages="$t('messages.employee_id_card_hint')"
                               :label="$t('labels.employee_id_card')"></TextField>
                    <TextareaField v-model="remark"
                                   v-if="!isElement"
                                   name="remark"
                                   ref="remark"
                                   :rows="2"
                                   :rules="{ max: 350 }"
                                   class-name="mt-1"
                                   :label="$t('labels.remarks')"></TextareaField>
                </v-flex>
            </v-layout>
        </ValidationObserver>
    </v-sheet>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapGetters, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import TextField from '@/components/widgets/forms/TextField';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import TextareaField from '@/components/widgets/forms/TextareaField';
import debouncedCompanies from '@/mixins/debounced/debouncedCompanies';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedPositions from '@/mixins/debounced/debouncedPositions';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import debouncedTemplates from '@/mixins/debounced/debouncedTemplates';
import debouncedEmployeesGroups from '@/mixins/debounced/debouncedEmployeesGroups';
import focusedFields from '@/mixins/focusedFields';
import ChangeLoginData from '@/components/employees/manageEmployee/ChangeLoginData';

export default {
    name: 'ManageEmployeeForm',
    components: { ValidationObserver, TextField, Autocomplete, TextareaField, ChangeLoginData },
    mixins: [
        debouncedCompanies,
        debouncedCustomers,
        debouncedSites,
        debouncedPositions,
        debouncedLocations,
        debouncedTemplates,
        debouncedEmployeesGroups,
        focusedFields
    ],
    props: {
        isEditable: {
            type: Boolean,
            'default': false
        },
        positionType: {
            type: String,
            required: false
        }
    },
    data: () => ({
        changeLoginVisibility: false,
        customerId: null,
        siteId: null
    }),
    computed: {
        canEditEmployee () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.EMPLOYEE);
        },
        canChangeLogin () {
            return this.$can(this.$abilityActions.CHANGE_PASS, this.$abilitySubjects.EMPLOYEE);
        },
        typeItemId () {
            return this.filterablePositions.filter(e => JSON.stringify(e.text) === '"Item"')[0]?.value;
        },
        isElement () {
            return this.positionType === 'elements' || this.position === this.typeItemId;
        },
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        },
        ...mapFields('manageEmployee', [
            'employeeData.firstName',
            'employeeData.lastName',
            'employeeData.city',
            'employeeData.address',
            'employeeData.birthDateAndPlace',
            'employeeData.nameId1',
            'employeeData.nameId2',
            'employeeData.email',
            'employeeData.phone',
            'employeeData.company',
            'employeeData.position',
            'employeeData.location',
            'employeeData.template',
            'employeeData.employeesGroups',
            'employeeData.nfcCode',
            'employeeData.idCard',
            'employeeData.remark'
        ]),
        ...mapFields('osm', [
            'device.deviceType',
            'device.deviceSerialNumber'
        ]),
        ...mapGetters('osm', [
            'deviceTypes'
        ]),
        ...mapGetters('cities', [
            'filterableCities'
        ]),
        ...mapGetters('companies', [
            'filterableCompanies'
        ]),
        ...mapGetters('positions', [
            'filterablePositions'
        ]),
        ...mapGetters('locations', [
            'locations'
        ]),
        ...mapGetters('templates', [
            'filterableTemplates'
        ]),
        ...mapGetters('employeesGroups', [
            'filterableEmployeesGroups'
        ]),
        ...mapState('manageEmployee', [
            'editEmployeeId',
            'employeeFormVisibility'
        ]),
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        formTitle () {
            return (this.isElement && this.$t('employees.default_data')) || this.$t('employees.personal_data');
        },
        positions () {
            if (this.isElement) {
                return this.filterablePositions.filter(e => JSON.stringify(e.text) === '"Item"');
            } else return this.filterablePositions.filter(e => JSON.stringify(e.text) !== '"Item"');
        },
        templatesFilteringParams () {
            return {
                'filter[point_id]': this.location
            };
        }
    },
    watch: {
        employeeFormVisibility: {
            handler (val) {
                if (val && this.isElement) {
                    this.getPositions({
                        'filter[name]': 'Item'
                    }).then(() => {
                        this.position = this.typeItemId;
                    });
                }
                if (val && this.isEditable) {
                    if (this.company) {
                        this.getCompanies({
                            'filter[id]': this.company
                        });
                    }

                    if (this.position) {
                        this.getPositions({
                            'filter[id]': this.position
                        });
                    }
                    if (this.location) {
                        this.getLocations(null, this.location);
                    }
                    if (this.template) {
                        this.getTemplates(null, this.templatesFilteringParams);
                    }
                }
            },
            immediate: true
        },
        focusedField: {
            handler () {
                this.setFocus(this.$refs);
            }
        }
    },
    mounted () {
        this.setFocus(this.$refs);
    },
    methods: {
        resetValidation () {
            this.$refs.obs.reset();
            if (this.$refs.loginForm) {
                this.$refs.loginForm.reset();
            }
        },
        isChangeLoginVisibility () {
            if (this.editEmployeeId) {
                return this.canChangeLogin && this.isEditable && !this.changeLoginVisibility;
            } else {
                return false;
            }
        },
        validate () {
            const obsValidation = this.$refs.obs.validate();
            if (this.positionType === 'elements') {
                return obsValidation;
            }
            const loginFormValidation = (this.changeLoginVisibility ||
                (!this.changeLoginVisibility && this.editEmployeeId === null))
                ? this.$refs.loginForm.validate() : true;
            return Promise.all([loginFormValidation, obsValidation]);
        },
        emitFetch () {
            this.$emit('fetch');
        },
        refreshEmployeeData () {
            this.$store.dispatch('manageEmployee/getActiveEmployee', { id: this.editEmployeeId })
                .then(() => {
                    this.$store.commit('manageEmployee/RESET_LOGIN_DATA');
                });
            this.emitFetch();
        },
        showChangeLogin () {
            this.changeLoginVisibility = true;
        },
        closeChangeLogin () {
            this.changeLoginVisibility = false;
        },
        locationChanged () {
            this.template = null;
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/_variables.scss";

.divider {
    padding-bottom: 5px;
    border-bottom: 1px solid $light_grey_2;
}
</style>
