<template>
    <div>
        <div v-if="item.places_count === 0" class="my-3 ">
            <v-icon size="20">{{ ICONS.MINUS }}</v-icon>
            <span class="text--xs">{{ item.text }}</span>
        </div>
        <a  v-if="item.places_count > 0" class="activator my-3">
            <span @click="toggleChildren"
                  :class="{'font-weight-bold': menuVisibility}">
                <v-icon size="20">{{ icon }}</v-icon>
                {{ item.text }}
            </span>
            <Checkbox v-if="item.points_count > 0"
                      :value="chosen"
                      @input="handleAssigning($event)"
                      dense
                      class-name="mt-0 ml-2 pt-0"></Checkbox>
        </a>
        <ul v-if="menuVisibility"
            class="sites-list my-3 pl-7">
            <li v-for="(site, i) in children"
                :key="i"
                class="my-3">
                <TreeViewSite
                    @update-assign="emitUpdateAssign()"
                    :type="type"
                    :id="id"
                    :item="site"></TreeViewSite>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import Checkbox from '@/components/widgets/forms/Checkbox';
import TreeViewSite from '@/components/templatesConfigurations/assignToLocations/treeView/TreeViewSite';

export default {
    name: 'TreeViewCustomer',
    components: { Checkbox, TreeViewSite },
    props: {
        type: {
            type: String,
            required: true,
            validate: (v) => {
                const types = ['templates', 'employees', 'kinds'];
                return types.includes(v);
            }
        },
        item: {
            type: Object,
            required: true
        },
        id: {
            type: [String, Number],
            required: true
        }
    },
    data: () => ({
        ICONS,
        chosen: false,
        children: [],
        totalChildrenAmount: null,
        menuVisibility: false,
        sitesPaginationPage: -1 // load all sites at once after getting rid of pagination and getSitesLazy function
    }),
    computed: {
        ...mapState('assignCustomer', [
            'assignedData'
        ]),
        icon () {
            return this.menuVisibility ? ICONS.MINUS : ICONS.PLUS;
        },
        isTemplateAssigned () {
            return this.assignedData !== null
                ? this.assignedData.customer_ids.includes(parseInt(this.item.value))
                : false;
        }
    },
    watch: {
        isTemplateAssigned: {
            handler (val) {
                this.chosen = val;
            },
            immediate: true
        }
    },
    methods: {
        toggleChildren () {
            this.menuVisibility ? this.hideChildren() : this.getSites();
        },
        getSitesAction () {
            return this.$store.dispatch('sites/getSites', {
                'filter[customer_id]': this.item.value,
                'page[number]': this.sitesPaginationPage
            });
        },
        getSites () {
            this.getSitesAction()
                .then((data) => {
                    this.children = [];
                    data.data.forEach(el => {
                        this.children.push({
                            value: el.id,
                            text: el.attributes.name,
                            points_count: parseInt(el.attributes.points_count, 10)
                        });
                    });
                    this.totalChildrenAmount = data.meta?.stats?.total.count;
                    this.menuVisibility = true;
                });
        },
        emitUpdateAssign () {
            this.$emit('update-assign');
        },
        hideChildren () {
            this.menuVisibility = false;
            this.children = [];
            this.totalChildrenAmount = null;
        },
        assignTemplate () {
            return this.$store.dispatch('templates/assignTemplatesToPoints', {
                templates_ids: [parseInt(this.id)],
                customer_ids: [parseInt(this.item.value)]
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        unassignTemplate () {
            return this.$store.dispatch('templates/unassignTemplatesFromPoints', {
                templates_ids: [parseInt(this.id)],
                customer_ids: [parseInt(this.item.value)]
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        assignKinds () {
            return this.$store.dispatch('incidents/assignKindsFromPoints', {
                templates_ids: [parseInt(this.id)],
                customer_ids: [parseInt(this.item.value)]
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        unassignKinds () {
            return this.$store.dispatch('incidents/unassignKindsFromPoints', {
                templates_ids: [parseInt(this.id)],
                customer_ids: [parseInt(this.item.value)]
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        assignEmployees () {
            return this.$store.dispatch('employees/manageAssignmentToLocations', {
                method: 'assign',
                records: {
                    employeesIds: [parseInt(this.id)],
                    customersIds: [parseInt(this.item.value)]
                }
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        unassignEmployees () {
            return this.$store.dispatch('employees/manageAssignmentToLocations', {
                method: 'unassign',
                records: {
                    employeesIds: [parseInt(this.id)],
                    customersIds: [parseInt(this.item.value)]
                }
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        handleAssigning (val) {
            if (val !== this.chosen) {
                this.chosen = val;
                switch (this.type) {
                case 'templates':
                    val ? this.assignTemplate() : this.unassignTemplate();
                    break;
                case 'employees':
                    val ? this.assignEmployees() : this.unassignEmployees();
                    break;
                case 'kinds':
                    val ? this.assignKinds() : this.unassignKinds();
                    break;
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
    .activator {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        &:hover {
            background-color: #eee;
        }
    }

    .sites-list {
        & li {
            list-style: none;
        }
    }

    ::v-deep {
        & .v-messages {
            display: none;
        }
    }
</style>
