import debounce from 'lodash.debounce';
import { mapState } from 'vuex';
import { FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        searchEmployeesGroups: null,
        isEmployeesGroupsLoading: false,
        employeesGroupsPaginationPage: 1,
        debouncedGetEmployeesGroups: null
    }),
    computed: {
        ...mapState('employeesGroups', [
            'rawEmployeesGroups',
            'totalEmployeesGroupsAmount'
        ])
    },
    watch: {
        searchEmployeesGroups: {
            handler (val) {
                if (!val || this.isEmployeesGroupsLoading) {
                    return;
                }

                this.debouncedGetEmployeesGroups(val);
            }
        }
    },
    methods: {
        getEmployeesGroups (additionalParams = null) {
            this.isEmployeesGroupsLoading = true;

            let params = {};

            if (typeof additionalParams === 'string') {
                params['filter[name][match]'] = additionalParams;
            } else if (typeof additionalParams === 'object') {
                params = { ...additionalParams };
            }

            return this.$store.dispatch('employeesGroups/getEmployeesGroups', params)
                .then(() => {
                    this.employeesGroupsPaginationPage = 1;
                })
                .finally(() => {
                    this.isEmployeesGroupsLoading = false;
                });
        },
        getEmployeesGroupsLazyLoading (additionalParams = null) {
            if (this.totalEmployeesGroupsAmount > this.rawEmployeesGroups.length) {
                this.isEmployeesGroupsLoading = true;

                this.employeesGroupsPaginationPage += 1;

                let params = {};

                if (typeof additionalParams === 'object') {
                    params = { ...additionalParams };
                }

                params['page[number]'] = this.employeesGroupsPaginationPage;

                const _name = this.searchEmployeesGroups || this.filtersSearching?.[FILTERS_SEARCHING.EMPLOYEES_GROUPS_NAME];

                if (_name) {
                    params['filter[name][match]'] = _name;
                }

                return this.$store.dispatch('employeesGroups/getEmployeesGroupsLazyLoading', params)
                    .finally(() => {
                        this.isEmployeesGroupsLoading = false;
                    });
            }
        }
    },
    created () {
        this.debouncedGetEmployeesGroups = debounce(this.getEmployeesGroups, 1000);
    }
};
