export default {
    computed: {
        canImportData () {
            return this.$can(this.$abilityActions.IMPORT_TEMPLATE, this.$abilitySubjects.TEMPLATE) ||
                this.$can(this.$abilityActions.IMPORT_IMPORT_POINT_XLSX, this.$abilitySubjects.LOCATION) ||
                this.$can(this.$abilityActions.IMPORT_EMPLOYEES_XLSX, this.$abilitySubjects.EMPLOYEE) ||
                this.$can(this.$abilityActions.IMPORT_PLACES_XLSX, this.$abilitySubjects.SITE) ||
                this.$can(this.$abilityActions.IMPORT_CUSTOMERS_XLSX, this.$abilitySubjects.CUSTOMER);
        }
    }
};
