import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('customers', [
            'focusedField'
        ])
    },
    methods: {
        setFocus (refs, nameField = 'name') {
            let focusedRef;
            if (!this.focusedField) {
                focusedRef = nameField;
            } else if (this.focusedField === 'address') {
                focusedRef = 'street_and_number';
            } else {
                focusedRef = this.focusedField;
            }
            // fix for vuetify bug
            this.$nextTick(() => {
                setTimeout(() => {
                    refs[focusedRef] && refs[focusedRef].$children[0].$children[0].$refs.input.focus();
                });
            });
        }
    }
};
