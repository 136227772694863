<template>
    <div class="d-inline-block">
        <HintingIcon :icon="ICONS.POINT_ADD"
                     @click="updateAssign()"
                     :tooltip="$t('actions.assign_to_locations')"></HintingIcon>

        <Modal :modal-title="$t('actions.assign_to_locations')"
               :dialog="dialog"
               max-width="1000px"
               @close="close"
               @visibility="dialog = $event">
            <template slot="content">
                <ul class="customer-list">
                    <InfiniteScroll tag="li"
                                    v-for="(customer, i) in listingCustomers"
                                    :key="i"
                                    :item="customer"
                                    :items-length="listingCustomers.length"
                                    :index="i"
                                    @load-more-items="getCustomersLazyLoading">
                        <TreeViewCustomer
                            :type="type"
                            :id="id"
                            @update-assign="updateAssign()"
                            :item="customer"></TreeViewCustomer>
                    </InfiniteScroll>
                </ul>
            </template>
        </Modal>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { ICONS } from '@/constants/icons';
import HintingIcon from '@/components/widgets/HintingIcon';
import Modal from '@/components/templates/Modal';
import TreeViewCustomer from '@/components/templatesConfigurations/assignToLocations/treeView/TreeViewCustomer';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import InfiniteScroll from '@/components/widgets/InfiniteScroll';

export default {
    name: 'ManageAssignToLocation',
    components: { HintingIcon, Modal, TreeViewCustomer, InfiniteScroll },
    mixins: [debouncedCustomers],
    props: {
        id: {
            type: [String, Number],
            required: true
        },
        type: {
            type: String,
            required: true,
            validate: (v) => {
                const types = ['templates', 'employees', 'kinds'];
                return types.includes(v);
            }
        }
    },
    data: () => ({
        ICONS,
        dialog: false
    }),
    computed: {
        ...mapGetters('customers', [
            'listingCustomers'
        ]),
        ...mapState('customers', [
            'totalCustomersAmount'
        ]),
        ...mapState('assignCustomer', [
            'assignedData'
        ])
    },
    methods: {
        updateAssign () {
            if (this.id) {
                this.dialog = true;
                const params = { type: this.type, id: this.id };
                this.$store.dispatch('assignCustomer/getDataForShowAssigned', params);
            }
        },
        close () {
            this.dialog = false;
            this.$store.commit('assignCustomer/CLEAR_EMPLOYEE_FOR_SHOW_POINTS');
        }
    },
    watch: {
        dialog: {
            handler (val) {
                if (val) this.getCustomers();
            }
        },
        immediate: true
    }
};
</script>

<style scoped lang="scss">
    .customer-list {
        padding-left: 0;
        & li {
            list-style: none;
        }
    }
</style>
