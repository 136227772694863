<template>
    <div class="d-inline-block">
        <HintingIcon :icon="ICONS.ADD"
                     class-name="mr-2"
                     @click="show"
                     :tooltip="$t('actions.add_template')"></HintingIcon>

        <Modal :modal-title="$t('employees.set_templates_title')"
               :dialog="dialog"
               max-width="1000px"
               @close="close"
               :closeIcon="false"
               @visibility="dialog = $event">
            <template slot="content">
                <Checkbox class="mt-0"
                          slotName="label"
                          v-model="selectAll">
                    <template>
                        <span class="font-weight-medium text--text">{{ $t('labels.select_all') }}</span>
                    </template>
                </Checkbox>
                <ul class="list">
                    <InfiniteScroll tag="li"
                                    v-for="(template, i) in filterableTemplates"
                                    :key="i"
                                    :item="template"
                                    :items-length="filterableTemplates.length"
                                    :index="i"
                                    @load-more-items="getTemplatesLazyLoading">
                        <Checkbox dense
                                  v-model="chosenOptions"
                                  multiple
                                  :label="template.text"
                                  :input-value="template.value"
                                  class-name="mt-0 ml-2 pt-0"></Checkbox>
                    </InfiniteScroll>
                </ul>
            </template>
            <template slot="actions">
                <v-layout align-center justify-end>
                    <v-btn color="secondary"
                           outlined
                           class="mr-3"
                           @click="close">{{ $t('buttons.cancel') }}</v-btn>
                    <v-btn color="secondary"
                           :loading="loader"
                           :disabled="loader"
                           @click="assignTemplates">{{ $t('buttons.save') }}</v-btn>
                </v-layout>
            </template>
        </Modal>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { ICONS } from '@/constants/icons';
import HintingIcon from '@/components/widgets/HintingIcon';
import Modal from '@/components/templates/Modal';
import debouncedTemplates from '@/mixins/debounced/debouncedTemplates';
import InfiniteScroll from '@/components/widgets/InfiniteScroll';
import Checkbox from '@/components/widgets/forms/Checkbox';

export default {
    name: 'AssignTemplatesToEmployee',
    components: { HintingIcon, Modal, InfiniteScroll, Checkbox },
    mixins: [debouncedTemplates],
    props: {
        inspectionsTemplates: {
            type: String,
            'default': ''
        },
        employeeId: {
            type: [Number, String],
            required: true
        }
    },
    data: () => ({
        ICONS,
        dialog: false,
        loader: false,
        chosenOptions: [],
        selectAll: null
    }),
    computed: {
        ...mapGetters('templates', [
            'filterableTemplates'
        ]),
        ...mapState('templates', [
            'totalTemplatesAmount'
        ])
    },
    methods: {
        show () {
            this.dialog = true;
            this.getTemplates();
        },
        close () {
            this.dialog = false;
            this.loader = false;
            this.$emit('fetch');
        },
        assignTemplates () {
            this.loader = true;
            const data = {
                employeeId: this.employeeId,
                templateId: this.chosenOptions
            };
            return this.$store.dispatch('employees/setAccessToTemplates', data)
                .finally(() => {
                    this.$emit('fetch');
                    this.close();
                });
        }
    },
    watch: {
        selectAll: {
            handler (val) {
                if (val && (this.chosenOptions.length !== this.filterableTemplates.length)) {
                    this.chosenOptions = [];
                    this.filterableTemplates.forEach(el => this.chosenOptions.push(el.value));
                } else if (val === false) {
                    this.chosenOptions = [];
                }
            }
        },
        dialog: {
            handler (val) {
                if (val && this.inspectionsTemplates.length > 0) {
                    this.chosenOptions = [...this.inspectionsTemplates.split(',')];
                } else {
                    this.chosenOptions = [];
                }
            }
        },
        immediate: true
    }
};
</script>

<style scoped lang="scss">
    .list {
        padding-left: 0;
        & li {
            list-style: none;
        }
    }
</style>
