<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-layout align-center
                  justify-space-between
                  class="mb-8">
            <v-flex>
                <GetFile :title="$t('buttons.print_all_qr_codes')"
                         class="mr-3"
                        @get-file="getAllQrCodes"
                        :loader="qrCodeLoader"></GetFile>
                <GetFile :title="$t('buttons.export_xls')"
                         :loader="xlsLoader"
                         class="mr-3"
                         @get-file="emitGetFile">
                    <template slot="icon">
                        <v-icon class="mr-1"
                                size="20">{{ ICONS.EXPORT }}</v-icon>
                    </template>
                </GetFile>
                <a v-if="canImportData" @click="showImport = true">
                    <v-icon>{{ ICONS.IMPORT }}</v-icon>
                    {{ importTitle }}
                </a>
            </v-flex>
            <v-flex>
                <v-layout justify-end>
                    <v-btn color="secondary"
                           v-if="canAddEmployee"
                           data-test="addNewEmployeeBtn"
                           @click="openEditForm()">{{ buttonTitle }}</v-btn>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-data-table :headers="headers"
                      :loading="loader"
                      disable-sort
                      v-model="selected"
                      :items="rawEmployees"
                      disable-pagination
                      hide-default-footer
                      show-select
                      item-key="id"
                      @dblclick:row="showRowDetails"
                      class="elevation-1">
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header }">
                <SortableHeader :header-item="header"
                                @sort="sort($event)"
                                :key="i"></SortableHeader>
            </template>

            <template v-slot:item.actions="{ item }">
                <HintingIcon class-name="mr-2"
                             :icon="ICONS.DETAILS"
                             @click="showDetails(item.id)"
                             data-test="showDetailsBtn"
                             :tooltip="$t('actions.view')"></HintingIcon>

                <HintingIcon class-name="mr-2"
                             v-if="canEditEmployee"
                             :icon="ICONS.EDIT"
                             @click="openEditForm(item)"
                             data-test="editEmployeeBtn"
                             :tooltip="$t('actions.edit')"></HintingIcon>

                <ButtonWithConfirmation :item-id="item.id"
                                        v-if="canDeleteEmployee"
                                        @action-executed="emitFetch"
                                        :callback="deleteEmployee"></ButtonWithConfirmation>

                <GetEmployeePdf :employee-id="item.id"></GetEmployeePdf>

                <AssignTemplatesToEmployee v-if="item.position_name !== 'Item' && canEditEmployee"
                                           :inspections-templates="item.templates_inspections"
                                           :employee-id="item.id"
                                           @fetch="emitFetch"></AssignTemplatesToEmployee>

                <ManageAssignToLocation v-if="item.position_name !== 'Item' && canEditEmployee"
                                        type="employees"
                                        :id="item.id"></ManageAssignToLocation>
            </template>
        </v-data-table>

        <div v-if="showGroupActions"
             class="mt-6">
            <ButtonWithConfirmation :item-id="selected"
                                    v-if="canDeleteEmployee"
                                    :callback="deleteManyEmployees">
                <template>
                    <a class="mr-3">
                        <v-icon size="20">{{ ICONS.DELETE }}</v-icon>
                        {{ $t('buttons.remove_selected') }}
                    </a>
                </template>
            </ButtonWithConfirmation>
            <GetFile :title="$t('buttons.print_qr_codes_for_selected_employees')"
                     :loader="selectedQRCodeLoader"
                     @get-file="getSelectedQRCodes"></GetFile>
        </div>

        <Pagination :pagination="pagination"
                    data-test="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>

        <EmployeeDetails v-if="employeeDetailsVisibility"
                         :position-type="positionType"
                         @close="closeDetails"
                         @fetch="emitFetch"
                         @visibility="handleDetailsVisibility($event)"></EmployeeDetails>

        <ImportEmployeesModal :dialog="showImport"
                              @fetch="emitFetch"
                              :position-type="positionType"
                              @close="showImport = false"
                              @visibility="showImport = $event"></ImportEmployeesModal>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import Pagination from '@/components/widgets/tables/Pagination';
import HintingIcon from '@/components/widgets/HintingIcon';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import EmployeeDetails from '@/components/employees/EmployeeDetails';
import AssignTemplatesToEmployee from '@/components/employees/AssignTemplatesToEmployee';
import GetEmployeePdf from '@/components/employees/GetEmployeePdf';
import GetFile from '@/components/widgets/buttons/GetFile';
import ImportEmployeesModal from '@/components/employees/import/ImportEmployeesModal';
import ManageAssignToLocation from '@/components/templatesConfigurations/assignToLocations/ManageAssignToLocation';
import ability from '@/mixins/ability';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'EmployeesTable',
    mixins: [ability, paginationSettings],
    components: {
        Pagination,
        HintingIcon,
        ButtonWithConfirmation,
        SortableHeader,
        EmployeeDetails,
        AssignTemplatesToEmployee,
        GetEmployeePdf,
        GetFile,
        ImportEmployeesModal,
        ManageAssignToLocation
    },
    props: {
        positionType: {
            type: String,
            required: false
        },
        loader: {
            type: Boolean,
            'default': false
        },
        xlsLoader: {
            type: Boolean,
            'default': false
        },
        changedPage: {
            type: [String, Number],
            required: false
        }
    },
    data: (vm) => ({
        importTitle: null,
        pagination: vm.setupPagination(),
        selected: [],
        buttonTitle: null,
        headers: [],
        ICONS,
        sortable: {
            login: null,
            last_name: 'desc',
            first_name: null,
            position_name: null,
            point_name: null
        },
        elementSortable: {
            login: null,
            last_name: null,
            first_name: null,
            name_id_1: null,
            name_id_2: null,
            position_name: null,
            point_name: null
        },
        detailsVisibility: false,
        qrCodeLoader: false,
        selectedQRCodeLoader: false,
        showImport: false
    }),
    computed: {
        ...mapState('employees', [
            'rawEmployees',
            'totalEmployeesAmount'
        ]),
        ...mapState('manageEmployee', [
            'employeeDetailsVisibility',
            'employeeDataForShowAssignedPoints'
        ]),
        ...mapState('locale', [
            'currentLocale'
        ]),
        defaultHeaders () {
            return [
                {
                    text: this.$t('labels.login'),
                    value: 'login',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('labels.last_name'),
                    value: 'last_name',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('labels.first_name'),
                    value: 'first_name',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('navigation.position'),
                    value: 'position_name',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('labels.location'),
                    value: 'point_name',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('labels.actions'),
                    value: 'actions',
                    icon: false,
                    width: '200px',
                    sortable: false
                }
            ];
        },
        elementsHeaders () {
            return [
                {
                    text: this.$t('labels.name_1'),
                    value: 'first_name',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('labels.name_2'),
                    value: 'last_name',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('labels.description_1'),
                    value: 'name_id_1',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('labels.description_2'),
                    value: 'name_id_2',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('labels.nfc_tag'),
                    value: 'nfccode',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('labels.location'),
                    value: 'point_name',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('labels.template'),
                    value: 'templates_names',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.actions'),
                    value: 'actions',
                    icon: false,
                    width: '200px',
                    sortable: false
                }
            ];
        },
        canAddEmployee () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.EMPLOYEE);
        },
        canEditEmployee () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.EMPLOYEE);
        },
        canEditTemplate () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.TEMPLATE);
        },
        canEditLocation () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.LOCATION);
        },
        canDeleteEmployee () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.EMPLOYEE);
        },
        showGroupActions () {
            return this.selected.length > 0;
        }
    },
    watch: {
        changedPage: {
            handler (val) {
                if (val !== this.pagination.page) {
                    this.pagination.page = val;
                }
            }
        },
        currentLocale: {
            handler (val, oldVal) {
                if (val !== oldVal) {
                    this.defineTableSetup();
                }
            },
            immediate: true
        },
        totalEmployeesAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        }
    },
    methods: {
        defineTableSetup () {
            switch (this.positionType) {
            case 'elements':
                this.buttonTitle = this.$t('elements.add_title');
                this.headers = this.elementsHeaders;
                this.sortable = { ...this.elementSortable };
                this.importTitle = this.$t('configurations.imports.import_elements');
                break;
            default:
                this.buttonTitle = this.$t('employees.add_title');
                this.headers = this.defaultHeaders;
                this.importTitle = this.$t('configurations.imports.import_employees');
            }
        },
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        sort (event) {
            switch (this.sortable[event]) {
            case null:
                this.sortable[event] = 'desc';
                break;
            case 'asc':
                this.sortable[event] = 'desc';
                break;
            case 'desc':
                this.sortable[event] = 'asc';
                break;
            }

            this.$emit('sort', {
                name: event,
                value: this.sortable[event]
            });
        },
        emitFetch () {
            this.$emit('fetch');
        },
        deleteEmployee (id) {
            return this.$store.dispatch('manageEmployee/deleteEmployee', id);
        },
        isElement () {
            return this.positionType === 'elements';
        },
        openEditForm (item = null) {
            if (item) {
                this.$store.dispatch('manageEmployee/getActiveEmployee', { id: item.id, isElement: this.isElement() })
                    .then(() => {
                        this.$store.commit('manageEmployee/SET_EMPLOYEE_FORM_VISIBILITY', true);
                    });
            } else {
                this.$store.commit('manageEmployee/SET_EMPLOYEE_FORM_VISIBILITY', true);
            }
        },
        showDetails (id) {
            this.$store.dispatch('manageEmployee/getSitesForActiveEmployee', id);
            this.$store.dispatch('employees/getEmployeeSubContractors', { id });
            this.$store.dispatch('manageEmployee/getActiveEmployee', { id, isElement: this.isElement() })
                .then(() => {
                    this.$store.commit('manageEmployee/SET_EMPLOYEE_DETAILS_VISIBILITY', true);
                });
        },
        closeDetails () {
            this.$store.commit('manageEmployee/SET_EMPLOYEE_DETAILS_VISIBILITY', false);
            this.$store.dispatch('manageEmployee/clearActiveEmployee');
        },
        handleDetailsVisibility (event) {
            this.$store.commit('manageEmployee/SET_EMPLOYEE_DETAILS_VISIBILITY', event);
        },
        getAllQrCodes () {
            this.qrCodeLoader = true;

            this.$store.dispatch('employees/getAllEmployeesQrCodes')
                .finally(() => {
                    this.qrCodeLoader = false;
                });
        },
        deleteManyEmployees (arrId) {
            return this.$store.dispatch('manageEmployee/deleteManyEmployees', arrId.map(el => parseInt(el.id)))
                .then(() => {
                    this.selected = [];
                    this.emitFetch();
                });
        },
        getSelectedQRCodes () {
            this.selectedQRCodeLoader = true;

            this.$store.dispatch('employees/getSelectedEmployeesQRCodes', this.selected.map(el => el.id))
                .then(() => {
                    this.selected = [];
                })
                .finally(() => {
                    this.selectedQRCodeLoader = false;
                });
        },
        emitGetFile () {
            this.$emit('get-file');
        },
        showRowDetails (event, { item }) {
            this.showDetails(item.id);
        }
    },
    created () {
        this.defineTableSetup();
    }
};
</script>

<style scoped>

</style>
