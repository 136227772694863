import debounce from 'lodash.debounce';
import { mapState } from 'vuex';
import { FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        searchPositions: null,
        isPositionsLoading: false,
        positionsPaginationPage: 1,
        debouncedGetPositions: null
    }),
    computed: {
        ...mapState('positions', [
            'rawPositions',
            'totalPositionsAmount'
        ])
    },
    watch: {
        searchPositions: {
            handler (val) {
                if (!val || this.isPositionsLoading) {
                    return;
                }

                this.debouncedGetPositions(val);
            }
        }
    },
    methods: {
        getPositions (additionalParams = null) {
            this.isPositionsLoading = true;

            let params = {};

            if (typeof additionalParams === 'string') {
                params['filter[name][match]'] = additionalParams;
            } else if (typeof additionalParams === 'object') {
                params = { ...additionalParams };
            }

            return this.$store.dispatch('positions/getPositions', params)
                .then(() => {
                    this.positionsPaginationPage = 1;
                })
                .finally(() => {
                    this.isPositionsLoading = false;
                });
        },
        getPositionsLazyLoading (additionalParams = null) {
            if (this.totalPositionsAmount > this.rawPositions.length) {
                this.isPositionsLoading = true;

                this.positionsPaginationPage += 1;

                let params = {};

                if (typeof additionalParams === 'object') {
                    params = { ...additionalParams };
                }

                params['page[number]'] = this.positionsPaginationPage;

                const _name = this.searchPositions || this.filtersSearching?.[FILTERS_SEARCHING.POSITIONS];

                if (_name) {
                    params['filter[name][match]'] = _name;
                }

                return this.$store.dispatch('positions/getPositionsLazyLoading', params)
                    .finally(() => {
                        this.isPositionsLoading = false;
                    });
            }
        }
    },
    created () {
        this.debouncedGetPositions = debounce(this.getPositions, 1000);
    }
};
