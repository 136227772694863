<template>
    <ValidationProvider :name="$attrs.label"
                        :vid="name"
                        :rules="rules"
                        v-slot="{ errors }">
        <v-textarea v-bind="$attrs"
                    v-on="$listeners"
                    no-resize
                    :error-messages="errors"
                    :class="className"
                    :rows="rows"
                    outlined>
            <template slot="label">
                {{ $attrs.label }}
                <span v-if="isRequired" class="error--text">*</span>
            </template>
        </v-textarea>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import isFieldRequired from '@/mixins/isFieldRequired';

export default {
    name: 'TextareaField',
    components: { ValidationProvider },
    mixins: [isFieldRequired],
    props: {
        name: {
            type: String,
            'default': ''
        },
        rules: {
            type: [String, Object, Array],
            'default': ''
        },
        className: {
            type: [String, Object, Array],
            'default': ''
        },
        rows: {
            type: [Number],
            'default': 5
        }
    }
};
</script>

<style scoped lang="scss">
    ::v-deep {
        & .v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) {
            & .v-label {
                top: 10px;
            }
        }
    }
</style>
