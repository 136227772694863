<template functional>
    <p class="error--text text-center mt-4">{{ parent.$t('errors.correct_fields') }}</p>
</template>

<script>
export default {
    name: 'ValidationError',
    functional: true
};
</script>
