<template>
    <Modal :modal-title="modalTitle"
           :dialog="employeeDetailsVisibility"
           max-width="1600px"
           data-test="modal"
           @close="close"
           @visibility="visibility($event)">
        <template slot="subtitle">
            <GetEmployeePdf :employee-id="activeEmployee.id"></GetEmployeePdf>
        </template>
        <template slot="content">
            <h3 class="font-weight-medium mb-3">{{ $t('labels.details') }}</h3>
            <v-row>
                <v-col md="8"
                       sm="12"
                       cols="12">
                    <v-sheet color="white"
                             class="pa-4"
                             elevation="1">
                        <v-layout align-stretch
                                  :wrap="isMobile">
                            <template v-if="isElements">
                                <v-flex sm6
                                        :class="{'bordered pr-4': smAndUp}">
                                    <InformationItem :title="$t('labels.name_1')"
                                                     name="nameId1"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.first_name"></InformationItem>
                                    <InformationItem :title="$t('labels.name_2')"
                                                     name="nameId2"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.last_name"></InformationItem>
                                    <InformationItem :title="$t('labels.description_1')"
                                                     name="nameId2"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.name_id_1"></InformationItem>
                                    <InformationItem :title="$t('labels.description_2')"
                                                     name="remark"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.name_id_2"></InformationItem>
                                </v-flex>
                                <v-flex sm6
                                        :class="{'bordered pl-4': smAndUp}">
                                    <InformationItem :title="$t('labels.customer')"
                                                     name="customer"
                                                     @edit="openEditForm()"
                                                     text=""></InformationItem>
                                    <InformationItem :title="$t('labels.site')"
                                                     name="site"
                                                     @edit="openEditForm()"
                                                     text=""></InformationItem>
                                    <InformationItem :title="$t('labels.location')"
                                                     name="location"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.point_name"></InformationItem>
                                    <InformationItem :title="$t('labels.template')"
                                                     name="template"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.templates_names[0]"></InformationItem>
                                    <InformationItem :title="$t('labels.nfc_tag')"
                                                     name="nfcCode"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.nfccode"></InformationItem>
                                    <!-- <InformationItem :title="$t('navigation.position')"
                                                     name="position"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.position_name"></InformationItem> -->
                                    <InformationItem :title="$t('navigation.elements_groups')"
                                                     name="employeesGroups"
                                                     @edit="openEditForm()"
                                                     :text="employeeGroupsNames"></InformationItem>
                                </v-flex>
                            </template>
                            <template v-else>
                                <v-flex sm6
                                        :class="{'bordered pr-4': smAndUp}">
                                    <InformationItem :title="$t('labels.login')"
                                                     name="login"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.login"></InformationItem>
                                    <InformationItem :title="$t('labels.employee_ID_1')"
                                                     name="nameId1"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.name_id_1"></InformationItem>
                                    <InformationItem :title="$t('labels.employee_ID_2')"
                                                     name="nameId2"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.name_id_2"></InformationItem>
                                    <!--<InformationItem :title="$t('labels.city')"-->
                                                     <!--name="city"-->
                                                     <!--@edit="openEditForm()"-->
                                                     <!--:text="activeEmployee.city_name"></InformationItem>-->
                                    <!--<InformationItem :title="$t('labels.street_and_number')"-->
                                                     <!--name="address"-->
                                                     <!--@edit="openEditForm()"-->
                                                     <!--:text="activeEmployee.address"></InformationItem>-->
                                    <!--<InformationItem :title="$t('labels.birth_date_and_place')"-->
                                                     <!--name="birthDateAndPlace"-->
                                                     <!--@edit="openEditForm()"-->
                                                     <!--:text="activeEmployee.birth_date_and_place"></InformationItem>-->
                                    <InformationItem :title="$t('labels.email')"
                                                     name="email"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.email"></InformationItem>
                                    <InformationItem :title="$t('labels.phone')"
                                                     name="phone"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.phone"></InformationItem>
                                    <InformationItem :title="$t('labels.remarks')"
                                                     name="remark"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.remark"></InformationItem>
                                    <InformationItem :title="$t('labels.sites')"
                                                     name="remark"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.remark">
                                        <ul v-if="employeeSitesDetails.length > 0"
                                            class="list">
                                            <li v-for="(el,i) in employeeSitesDetails" :key="i">{{el.name}}</li>
                                        </ul>
                                    </InformationItem>

                                    <InformationItem :title="$t('labels.subcontractors')"
                                                     name="remark"
                                                     :text="activeEmployee.remark">
                                        <ul v-if="employeeAssignedSubContractors.length > 0"
                                            class="list">
                                            <li v-for="(el,i) in employeeAssignedSubContractors" :key="i">{{el.name}}</li>
                                        </ul>
                                        <HintingIcon :icon="ICONS.ADD"
                                            class-name="mr-2"
                                            @click="gotoAssignSubContractors"
                                            :tooltip="$t('actions.assing_subcontractor')"></HintingIcon>
                                    </InformationItem>
                                    <InformationItem :title="$t('labels.deviceType')"
                                        name="deviceType"
                                        :clickable="false"
                                        :text="device.deviceType"></InformationItem>
                                    <InformationItem :title="$t('labels.deviceSerialNumber')"
                                        name="deviceSerialNumber"
                                        :clickable="false"
                                        :text="device.deviceSerialNumber"></InformationItem>
                                </v-flex>

                                <v-flex sm6
                                        :class="{'bordered pl-4': smAndUp}">
                                    <InformationItem :title="$t('labels.company')"
                                                     name="company"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.company_name"></InformationItem>
                                    <InformationItem :title="$t('navigation.position')"
                                                     name="position"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.position_name"></InformationItem>
                                    <InformationItem :title="$t('labels.manager')"
                                                     name="supervisor"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.supervisor_full_name"></InformationItem>
                                    <InformationItem :title="$t('labels.location')"
                                                     name="location"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.point_name"></InformationItem>
                                    <InformationItem :title="$t('navigation.employees_groups')"
                                                     name="employeesGroups"
                                                     @edit="openEditForm()"
                                                     :text="employeeGroupsNames"></InformationItem>
                                    <InformationItem :title="$t('labels.nfc_tag')"
                                                     name="nfcCode"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.nfccode"></InformationItem>
                                    <InformationItem :title="$t('labels.employee_id_card')"
                                                     name="idCard"
                                                     @edit="openEditForm()"
                                                     :text="activeEmployee.kronos_id"></InformationItem>
                                    <InformationItem :title="$t('labels.templates')"
                                                     name="templates"
                                                     :clickable="false">
                                        <template>
                                            <ul v-if="inspectionTemplates.length > 0"
                                                class="list">
                                                <li v-for="(el,i) in inspectionTemplates" :key="i">{{el}}</li>
                                            </ul>
                                            <AssignTemplatesToEmployee :inspections-templates="activeEmployee.templates_inspections"
                                                                       :employee-id="editEmployeeId"
                                                                       @fetch="refreshEmployeeData"></AssignTemplatesToEmployee>
                                        </template>
                                    </InformationItem>
                                </v-flex>
                            </template>
                        </v-layout>
                        <v-layout justify-end>
                            <v-btn color="secondary"
                                   @click="redirectHistory"
                                   :disabled="!(this.activeEmployee.nfccode  || this.activeEmployee.qrcode)"
                                   data-test="historyBtn">{{ $t('buttons.history') }}
                            </v-btn>
                            <div style="padding-left: 5px"></div>
                            <v-btn color="secondary"
                                   v-if="canEditEmployee"
                                   @click="openEditForm"
                                   data-test="editBtn">{{ $t('buttons.edit') }}
                            </v-btn>
                        </v-layout>
                    </v-sheet>
                </v-col>
                <v-col md="4"
                       sm="12"
                       cols="12">
                    <v-layout align-center justify-center>
                        <v-img v-if="qrCode"
                               max-width="320px"
                               :src="qrCode"></v-img>
                    </v-layout>
                </v-col>
            </v-row>
        </template>
    </Modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import InformationItem from '@/components/customers/InformationItem';
import AssignTemplatesToEmployee from '@/components/employees/AssignTemplatesToEmployee';
import GetEmployeePdf from '@/components/employees/GetEmployeePdf';
import { ICONS } from '@/constants/icons';
import HintingIcon from '@/components/widgets/HintingIcon';

export default {
    name: 'EmployeeDetails',
    components: { Modal, InformationItem, AssignTemplatesToEmployee, GetEmployeePdf, HintingIcon },
    props: {
        positionType: {
            type: String,
            required: false
        }
    },
    data: () => ({
        ICONS,
        changeLoginVisibility: false
    }),
    computed: {
        ...mapState('manageEmployee', [
            'editEmployeeId',
            'activeEmployee',
            'employeeDetailsVisibility'
        ]),
        ...mapState('osm', [
            'device'
        ]),
        ...mapGetters('templates', [
            'filterableTemplates'
        ]),
        ...mapGetters('manageEmployee', [
            'employeeSitesDetails'
        ]),
        ...mapGetters('employees', [
            'employeeAssignedSubContractors'
        ]),
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        isElements () {
            return this.positionType === 'elements';
        },
        modalTitle () {
            return this.isElements ? this.activeEmployee.position_name : this.activeEmployee.full_name;
        },
        smAndUp () {
            return this.$vuetify.breakpoint.smAndUp;
        },
        inspectionTemplates () {
            if (this.activeEmployee.templates_inspections) {
                const temInsp = this.activeEmployee.templates_inspections.split(',');
                return this.filterableTemplates.filter(el => temInsp.find(t => t === el.value)).map(t => t.text);
            } else return [];
        },
        canEditEmployee () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.EMPLOYEE);
        },
        qrCode () {
            if (this.activeEmployee.qr_code_svg) {
                return `data:image/svg+xml;base64,${this.activeEmployee.qr_code_svg}`;
            }
            return '';
        },
        employeeGroupsNames () {
            return this.activeEmployee.group_names ? this.activeEmployee.group_names.join(', ') : '';
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        emitFetch () {
            this.$emit('fetch');
        },
        redirectHistory () {
            if (this.activeEmployee.nfccode || this.activeEmployee.qrcode) {
                this.$router.push({ name: 'inspectionsSummary', query: { scan: this.activeEmployee.full_name } });
            }
        },
        openEditForm () {
            this.$store.commit('manageEmployee/SET_EMPLOYEE_DATA', this.activeEmployee);
            this.$store.commit('manageEmployee/SET_EMPLOYEE_FORM_VISIBILITY', true);
        },
        refreshEmployeeData () {
            this.$store.dispatch('manageEmployee/getActiveEmployee', { id: this.editEmployeeId })
                .then(() => {
                    this.$store.commit('manageEmployee/RESET_LOGIN_DATA');
                });
            this.emitFetch();
        },
        showChangeLogin () {
            this.changeLoginVisibility = true;
        },
        closeChangeLogin () {
            this.changeLoginVisibility = false;
            this.$store.commit('manageEmployee/RESET_LOGIN_DATA');
        },
        gotoAssignSubContractors () {
            this.$router.push({ name: 'subContractors' });
        }
    }
};
</script>

<style scoped lang="scss">
.list {
    padding-left: 0.7rem;
& li {
      list-style: number;
  }
}
</style>
