<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.elements') }}</h1>
        <FiltersBar></FiltersBar>
        <EmployeesTable :loader="isEmployeesLoading"
                        :position-type="positionType"
                        :changed-page="pagination.page"
                        :xls-loader="xlsLoader"
                        @get-file="getXLS"
                        @sort="sortingColumn = $event"
                        @fetch="fetchElements"
                        @change-page="changePage($event)"
                        @change-amount="changeAmount($event)"></EmployeesTable>

        <ManageEmployee :position-type="positionType"
                        @fetch="fetchElements"></ManageEmployee>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import debounce from 'lodash.debounce';
import EmployeesTable from '@/components/employees/EmployeesTable';
import debouncedEmployees from '@/mixins/debounced/debouncedEmployees';
import debouncedEmployeesGroups from '@/mixins/debounced/debouncedEmployeesGroups';
import debouncedCompanies from '@/mixins/debounced/debouncedCompanies';
import filtering from '@/mixins/filtering';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import ManageEmployee from '@/components/employees/manageEmployee/ManageEmployee';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'Elements',
    mixins: [debouncedEmployees, debouncedEmployeesGroups, debouncedCompanies, filtering, paginationSettings],
    components: { EmployeesTable, FiltersBar, ManageEmployee },
    data: (vm) => ({
        pagination: vm.setupPagination(),
        positionType: 'elements',
        sortingColumn: null,
        debouncedFetchElements: null,
        xlsLoader: false
    }),
    computed: {
        ...mapState('employees', [
            'rawEmployees',
            'totalEmployeesAmount'
        ]),
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        ...mapFields('search', [
            'search'
        ]),
        filtersConfig () {
            return [
                new AutocompleteFilter({
                    model: FILTERS.EMPLOYEE_GROUP_ID,
                    label: this.$t('navigation.employees_groups'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.EMPLOYEES_GROUPS_NAME,
                    lazy: this.getEmployeesGroupsLazyLoading,
                    searchCallback: this.debouncedGetEmployeesGroups,
                    items: {
                        obj: 'getters',
                        module: 'employeesGroups',
                        prop: 'filterableEmployeesGroups'
                    }
                })
            ];
        }
    },
    watch: {
        sortingColumn: {
            handler () {
                this.fetchElements();
            },
            deep: true
        },
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    this.debouncedFetchElements();
                }
            }
        }
    },
    methods: {
        generateFilters () {
            const params = {};

            this.filtersConfig.forEach(filter => {
                const f = filter.model;

                if (this.filters[f] && this.filters[f].length > 0) {
                    params[`filter[${f}]`] = this.filters[f].join(',');
                }
            });

            return params;
        },
        fetchElements () {
            const params = {
                'filter[is_item]': true,
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount,
                ...this.generateFilters()
            };

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            if (this.search) {
                params['filter[item_search]'] = this.search;
            }

            this.getEmployees(params);
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchElements();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchElements();
        },
        getXLS () {
            this.xlsLoader = true;

            const params = {
                instance: 'employees',
                type: 'elements',
                prefix: 'conf',
                ...this.generateFilters()
            };

            if (this.search) {
                params['filter[item_search]'] = this.search;
            }

            return this.$store.dispatch('commonActions/getXLS', params)
                .finally(() => {
                    this.xlsLoader = false;
                });
        }
    },
    mounted () {
        this.debouncedFetchElements = debounce(this.fetchElements, 1000);
        this.setFiltersWatcher(this.debouncedFetchElements);
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.EMPLOYEE)) {
                vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                vm.$store.dispatch('employeesGroups/getEmployeesGroups')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.EMPLOYEE_GROUP_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.fetchElements();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (from, to, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        this.search = null;
        this.debouncedFetchElements = null;
        next();
    }
};
</script>

<style scoped>

</style>
