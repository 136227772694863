<template>
    <Modal :with-activatior="false"
           :dialog="dialog"
           max-width="400px"
           dense
           :closeIcon="false"
           @keydown.enter="close"
           @keydown.esc="close"
           :with-close-button="true"
           @visibility="visibility($event)">
        <template slot="content">
            <p class="mb-0 text-center text--m">{{ text }}</p>
            <v-layout align-center justify-center class="py-5">
                <v-btn color="secondary"
                       @click="close">{{ $t('buttons.ok') }}</v-btn>
            </v-layout>
        </template>
        <template slot="actions">
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/templates/Modal';

export default {
    name: 'InformationDialog',
    components: { Modal },
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        text: {
            type: String,
            'default': ''
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        visibility (event) {
            this.$emit('visibility', event);
        }
    }
};
</script>

<style scoped>

</style>
