<template>
    <Modal :modal-title="title"
           :dialog="dialog"
           max-width="800px"
           dense
           data-test="modal"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <ImportEmployeesForm ref="form"
                                 :position-type="positionType"
                                 @fetch="$emit('fetch')"></ImportEmployeesForm>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/templates/Modal';
import ImportEmployeesForm from '@/components/employees/import/ImportEmployeesForm';

export default {
    name: 'ImportEmployeesModal',
    components: { Modal, ImportEmployeesForm },
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        positionType: {
            type: String,
            required: false
        }
    },
    data: () => ({
        importTitle: null
    }),
    computed: {
        title () {
            return this.positionType === 'elements' ? this.$t('configurations.imports.import_elements')
                : this.$t('configurations.imports.import_employees');
        }
    },
    methods: {
        close () {
            this.$emit('close');
            this.$refs.form.clearFile();
        },
        visibility (event) {
            this.$emit('visibility', event);
        }
    }
};
</script>

<style scoped>

</style>
