<template>
    <GetFile :with-title="false"
            :loader="loader"
            @get-file="getPdf">
        <template slot="icon">
            <HintingIcon class-name="mr-2"
                         :icon="ICONS.QR_CODE"
                         :tooltip="$t('actions.qr_code')"></HintingIcon>
        </template>
    </GetFile>
</template>

<script>
import { ICONS } from '@/constants/icons';
import GetFile from '@/components/widgets/buttons/GetFile';
import HintingIcon from '@/components/widgets/HintingIcon';

export default {
    name: 'GetEmployeePdf',
    components: { GetFile, HintingIcon },
    props: {
        employeeId: {
            type: [Number, String],
            required: true
        }
    },
    data: () => ({
        ICONS,
        loader: false
    }),
    methods: {
        getPdf () {
            this.loader = true;

            this.$store.dispatch('manageEmployee/getEmployeeQrCode', this.employeeId)
                .finally(() => {
                    this.loader = false;
                });
        }
    }
};
</script>

<style scoped>

</style>
