<template>
    <v-progress-circular indeterminate
                         v-bind="$attrs"
                         :size="size"
                         :width="width"
                         :color="color"></v-progress-circular>
</template>

<script>
export default {
    name: 'CircularLoader',
    props: {
        size: {
            type: [String, Number],
            'default': 16
        },
        width: {
            type: [String, Number],
            'default': 2
        },
        color: {
            type: String,
            'default': 'primary'
        }
    }
};
</script>

<style scoped>

</style>
