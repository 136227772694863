import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        debouncedGetCustomers: null,
        isCustomersLoading: false,
        searchCustomers: null,
        customersPaginationPage: 1
    }),
    computed: {
        ...mapState('customers', [
            'totalCustomersAmount'
        ]),
        ...mapGetters('customers', [
            'listingCustomers'
        ])
    },
    watch: {
        searchCustomers: {
            handler (val, oldVal) {
                if (!val || this.isCustomersLoading || val === oldVal) {
                    return;
                }

                this.debouncedGetCustomers(val);
            }
        }
    },
    methods: {
        getCustomers (val, searchID = null, withStructure = false) {
            this.isCustomersLoading = true;

            const params = {};

            if (searchID) {
                params['filter[id]'] = searchID;
            } else {
                params['filter[search]'] = val;
            }

            if (withStructure) {
                params['filter[customers_with_structure]'] = true;
            }

            return this.$store.dispatch('customers/getCustomers', params)
                .then(() => {
                    this.customersPaginationPage = 1;
                })
                .finally(() => {
                    this.isCustomersLoading = false;
                });
        },
        getCustomersLazyLoading (withStructure = false) {
            if (this.totalCustomersAmount > this.listingCustomers.length) {
                this.isCustomersLoading = true;

                this.customersPaginationPage += 1;

                const params = {
                    'page[number]': this.customersPaginationPage
                };

                if (withStructure) {
                    params['filter[customers_with_structure]'] = true;
                }

                let _name;
                if (this.searchCustomers) {
                    _name = this.searchCustomers;
                } else if (this.filtersSearching?.[FILTERS_SEARCHING.CUSTOMER]) {
                    _name = this.filtersSearching?.[FILTERS_SEARCHING.CUSTOMER];
                } else if (this.modalFiltersSearching?.[FILTERS_SEARCHING.CUSTOMER]) {
                    _name = this.modalFiltersSearching?.[FILTERS_SEARCHING.CUSTOMER];
                }

                if (_name) {
                    params['filter[search]'] = _name;
                }

                return this.$store.dispatch('customers/getCustomersLazyLoading', params)
                    .finally(() => {
                        this.isCustomersLoading = false;
                    });
            }
        },
        referralCustomerCallback (newVal) {
            const filterPropertyName = this.isModal ? 'modalFiltersConfig' : 'filtersConfig';
            const mutationTitle = this.isModal ? 'filters/SET_MODAL_CONFIG' : 'filters/SET_CONFIG';

            if (newVal && newVal.length > 0) {
                this[filterPropertyName].find(el => el.model === FILTERS.SITE_ID).disabled = false;
                this[filterPropertyName].find(el => el.model === FILTERS.SITE_ID).successMessages = '';
                this.debouncedGetSites(null, null, newVal);
            } else {
                this[filterPropertyName].find(el => el.model === FILTERS.SITE_ID).disabled = true;
                this[filterPropertyName].find(el => el.model === FILTERS.SITE_ID).successMessages = this.$t('messages.choose_customer');
                const locationFilter = this[filterPropertyName].find(el => el.model === FILTERS.LOCATION_ID);
                if (locationFilter) {
                    locationFilter.disabled = true;
                    locationFilter.successMessages = this.$t('messages.choose_site');
                }

                this.$refs.filtersBar.resetSpecifyFilter(FILTERS.SITE_ID);
                this.$refs.filtersBar.resetSpecifyFilter(FILTERS.LOCATION_ID);
            }
            this.$store.commit(mutationTitle, this[filterPropertyName]);
        }
    },
    created () {
        this.debouncedGetCustomers = debounce(this.getCustomers, 1000);
    }
};
