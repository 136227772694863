<template>
    <div>
        <div v-if="item.points_count === 0">
            <v-icon size="18">{{ ICONS.MINUS }}</v-icon>
            <span class="text--xs">{{ item.text }}</span>
        </div>
        <a  v-if="item.points_count > 0" class="activator">
            <span @click="toggleChildren">
                <v-icon size="18">{{ icon }}</v-icon>
                <span class="text--xs">{{ item.text }}</span>
            </span>
            <Checkbox dense
                      :value="chosen"
                      @input="handleAssigning($event)"
                      class-name="mt-0 ml-2 pt-0"></Checkbox>
        </a>
        <ul v-if="submenuVisibility"
            :class="{'my-3': submenuVisibility}"
            class="locations-list pl-7">
            <InfiniteScroll tag="li"
                            v-for="(location, i) in children"
                            :key="i"
                            :item="location"
                            :items-length="children.length"
                            :index="i"
                            @load-more-items="getLocationsLazy">
                <TreeViewLocation
                    @update-assign="emitUpdateAssign()"
                    :type="type"
                    :itemId="id"
                    :item="location"></TreeViewLocation>
            </InfiniteScroll>
        </ul>
    </div>
</template>

<script>
import { ICONS } from '@/constants/icons';
import Checkbox from '@/components/widgets/forms/Checkbox';
import TreeViewLocation from '@/components/templatesConfigurations/assignToLocations/treeView/TreeViewLocation';
import InfiniteScroll from '@/components/widgets/InfiniteScroll';
import { mapState } from 'vuex';

export default {
    name: 'TreeViewSite',
    components: { Checkbox, TreeViewLocation, InfiniteScroll },
    props: {
        type: {
            type: String,
            required: true,
            validate: (v) => {
                const types = ['templates', 'employees', 'kinds'];
                return types.includes(v);
            }
        },
        item: {
            type: Object,
            required: true
        },
        id: {
            type: [String, Number],
            required: true
        }
    },
    data: () => ({
        ICONS,
        submenuVisibility: false,
        chosen: false,
        children: [],
        totalChildrenAmount: null,
        locationsPaginationPage: 1,
        alreadyRendered: false
    }),
    computed: {
        ...mapState('assignCustomer', [
            'assignedData'
        ]),
        icon () {
            return this.submenuVisibility ? ICONS.MINUS : ICONS.PLUS;
        },
        isTemplateAssigned () {
            return this.assignedData !== null
                ? this.assignedData.place_ids.includes(parseInt(this.item.value))
                : false;
        }
    },
    watch: {
        isTemplateAssigned: {
            handler (val) {
                this.chosen = val;
            },
            immediate: true
        }
    },
    methods: {
        toggleChildren () {
            this.submenuVisibility ? this.hideChildren() : this.getLocations();
        },
        getLocationsAction () {
            return this.$store.dispatch('locations/getLocations', {
                'filter[place_id]': this.item.value,
                'page[number]': this.locationsPaginationPage
            });
        },
        emitUpdateAssign () {
            this.$emit('update-assign');
        },
        getLocations () {
            this.getLocationsAction()
                .then((data) => {
                    this.children = [];
                    data.data.forEach(el => this.children.push({
                        value: el.id,
                        text: el.attributes.name
                    }));
                    this.totalChildrenAmount = data.meta?.stats?.total.count;
                    this.locationsPaginationPage = 1;
                    this.submenuVisibility = true;
                });
        },
        getLocationsLazy () {
            if (this.totalChildrenAmount > this.children.length) {
                this.locationsPaginationPage += 1;

                this.getLocationsAction()
                    .then((data) => {
                        data.data.forEach(el => this.children.push({
                            value: el.id,
                            text: el.attributes.name
                        }));
                        this.totalChildrenAmount = data.meta?.stats?.total.count;
                    });
            }
        },
        hideChildren () {
            this.submenuVisibility = false;
            this.children = [];
            this.totalChildrenAmount = null;
        },
        assignTemplate () {
            return this.$store.dispatch('templates/assignTemplatesToPoints', {
                templates_ids: [parseInt(this.id)],
                places_ids: [parseInt(this.item.value)]
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        unassignTemplate () {
            return this.$store.dispatch('templates/unassignTemplatesFromPoints', {
                templates_ids: [parseInt(this.id)],
                places_ids: [parseInt(this.item.value)]
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        assignEmployees () {
            return this.$store.dispatch('employees/manageAssignmentToLocations', {
                method: 'assign',
                records: {
                    employeesIds: [parseInt(this.id)],
                    sitesIds: [parseInt(this.item.value)]
                }
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        unassignEmployees () {
            return this.$store.dispatch('employees/manageAssignmentToLocations', {
                method: 'unassign',
                records: {
                    employeesIds: [parseInt(this.id)],
                    sitesIds: [parseInt(this.item.value)]
                }
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        assignKinds () {
            return this.$store.dispatch('incidents/assignKindsFromPoints', {
                templates_ids: [parseInt(this.id)],
                places_ids: [parseInt(this.item.value)]
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        unassignKinds () {
            return this.$store.dispatch('incidents/unassignKindsFromPoints', {
                templates_ids: [parseInt(this.id)],
                places_ids: [parseInt(this.item.value)]
            }).finally(() => {
                this.emitUpdateAssign();
            });
        },
        handleAssigning (val) {
            if (val !== this.chosen) {
                this.chosen = val;
                switch (this.type) {
                case 'templates':
                    val ? this.assignTemplate() : this.unassignTemplate();
                    break;
                case 'employees':
                    val ? this.assignEmployees() : this.unassignEmployees();
                    break;
                case 'kinds':
                    val ? this.assignKinds() : this.unassignKinds();
                    break;
                }
            }
            if (this.alreadyRendered) {
                this.emitUpdateAssign();
            } else {
                this.alreadyRendered = true;
            }
        }
    }
};
</script>

<style scoped lang="scss">
    .activator {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        &:hover {
            background-color: #eee;
        }
    }

    .locations-list {
        & li {
            list-style: none;
        }
    }
</style>
