<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.persons') }}</h1>
        <FiltersBar></FiltersBar>
        <EmployeesTable :loader="isEmployeesLoading"
                        :changed-page="pagination.page"
                        :xls-loader="xlsLoader"
                        @get-file="getXLS"
                        @sort="sortingColumn = $event"
                        @fetch="fetchEmployees"
                        @change-page="changePage($event)"
                        @change-amount="changeAmount($event)"></EmployeesTable>

        <ManageEmployee :position-type="positionType" @fetch="fetchEmployees"></ManageEmployee>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import debounce from 'lodash.debounce';
import EmployeesTable from '@/components/employees/EmployeesTable';
import debouncedEmployees from '@/mixins/debounced/debouncedEmployees';
import debouncedEmployeesGroups from '@/mixins/debounced/debouncedEmployeesGroups';
import debouncedCompanies from '@/mixins/debounced/debouncedCompanies';
import debouncedPositions from '@/mixins/debounced/debouncedPositions';
import filtering from '@/mixins/filtering';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import ManageEmployee from '@/components/employees/manageEmployee/ManageEmployee';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'Employees',
    mixins: [debouncedEmployees, debouncedEmployeesGroups, debouncedCompanies, debouncedPositions, filtering, paginationSettings],
    components: { EmployeesTable, FiltersBar, ManageEmployee },
    data: (vm) => ({
        pagination: vm.setupPagination(),
        positionType: 'employees',
        sortingColumn: null,
        debouncedFetchEmployees: null,
        xlsLoader: false
    }),
    computed: {
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        ...mapFields('search', [
            'search'
        ]),
        filtersConfig () {
            return [
                new AutocompleteFilter({
                    model: FILTERS.EMPLOYEE_GROUP_ID,
                    label: this.$t('navigation.employees_groups'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.EMPLOYEES_GROUPS_NAME,
                    lazy: this.getEmployeesGroupsLazyLoading,
                    searchCallback: this.debouncedGetEmployeesGroups,
                    items: {
                        obj: 'getters',
                        module: 'employeesGroups',
                        prop: 'filterableEmployeesGroups'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.COMPANY_ID,
                    label: this.$t('navigation.companies'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.COMPANIES,
                    lazy: this.getCompaniesLazyLoading,
                    searchCallback: this.debouncedGetCompanies,
                    items: {
                        obj: 'getters',
                        module: 'companies',
                        prop: 'filterableCompanies'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.POSITION_ID,
                    label: this.$t('navigation.position'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.POSITIONS,
                    lazy: this.getPositionsLazyLoading,
                    searchCallback: this.debouncedGetPositions,
                    items: {
                        obj: 'getters',
                        module: 'positions',
                        prop: 'humanPositions'
                    }
                })
            ];
        }
    },
    watch: {
        sortingColumn: {
            handler () {
                this.fetchEmployees();
            },
            deep: true
        },
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    this.debouncedFetchEmployees();
                }
            }
        }
    },
    methods: {
        generateFilters () {
            const params = {};

            this.filtersConfig.forEach(filter => {
                const f = filter.model;

                if (this.filters[f] && this.filters[f].length > 0) {
                    params[`filter[${f}]`] = this.filters[f].join(',');
                }
            });

            return params;
        },
        fetchEmployees () {
            const params = {
                'filter[is_item]': false,
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount,
                // 'extra_fields[employees]': 'inspector_relations_ids',
                ...this.generateFilters()
            };

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            if (this.search) {
                params['filter[search]'] = this.search;
            }
            this.getEmployees(params);
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchEmployees();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchEmployees();
        },
        getXLS () {
            this.xlsLoader = true;

            const params = {
                instance: 'employees',
                prefix: 'conf',
                type: 'employees',
                ...this.generateFilters()
            };

            if (this.search) {
                params['filter[item_search]'] = this.search;
            }

            return this.$store.dispatch('commonActions/getXLS', params)
                .finally(() => {
                    this.xlsLoader = false;
                });
        }
    },
    mounted () {
        this.debouncedFetchEmployees = debounce(this.fetchEmployees, 1000);
        this.setFiltersWatcher(this.debouncedFetchEmployees);
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.EMPLOYEE)) {
                vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                vm.$store.dispatch('employeesGroups/getEmployeesGroups')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.EMPLOYEE_GROUP_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('companies/getCompanies')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.COMPANY_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('positions/getPositions')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.POSITION_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.fetchEmployees();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeDestroy () {
        this.$store.dispatch('filters/clearFiltersParams');
        this.debouncedFetchEmployees = null;
        this.debouncedGetEmployees = null;
    },
    beforeRouteLeave (to, from, next) {
        this.debouncedFetchEmployees = null;
        this.debouncedGetEmployees = null;
        this.$store.dispatch('filters/clearFiltersParams');
        this.search = null;
        next();
    }
};
</script>

<style scoped>

</style>
